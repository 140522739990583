import {
  Box,
  BoxProps,
  Heading,
  Table,
  TableContainer,
  Tbody
} from '@chakra-ui/react';
import { TournamentRankingType } from './types';
import { TournamentRankingLine } from './TournamentRankingLine';
import { toRem } from '@/helpers/toRem';

type TournamentPersonalRankingProps = BoxProps & {
  title: string;
  ranking: TournamentRankingType;
};

export const TournamentPersonalRanking = ({
  title,
  ranking,
  ...props
}: TournamentPersonalRankingProps) => {
  return (
    <Box mt={12} {...props}>
      <Heading
        as="h4"
        fontSize={[toRem(18), toRem(24)]}
        lineHeight={[toRem(24), toRem(30)]}
      >
        {title}
      </Heading>
      <Box mt={8} borderRadius={'ten'} overflow={'hidden'}>
        <TableContainer>
          <Table>
            <Tbody>
              <TournamentRankingLine highlighted isLast ranking={ranking} />
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
