import { Text, TextProps } from '@chakra-ui/react';

type HtmlContentProps = TextProps & {
  html: string;
};
export const HtmlContent = ({ html, ...props }: HtmlContentProps) => {
  return (
    <Text
      as="div"
      maxWidth={'100%'}
      {...props}
      dangerouslySetInnerHTML={{
        __html: html
      }}
    />
  );
};
