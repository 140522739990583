import useVisibilityChange from '@/hooks/useVisibilityChange';
import { Img } from '@chakra-ui/react';
import { PortalBackground } from '@/components/Portal';

const OriaBackground = () => {
  const video = document.getElementById('bgVideo') as HTMLMediaElement;
  useVisibilityChange(video?.play, video?.pause);

  return (
    <PortalBackground>
      <Img
        src="/oria/backgrounds/top.webp"
        position="absolute"
        loading="lazy"
        zIndex={-1}
        left="50%"
        top="0"
        transform="translateX(-50%)"
      />
    </PortalBackground>
  );
};

export default OriaBackground;
