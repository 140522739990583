import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { getUrlInfoFromRouter } from '@/utils/multiDomains';
import { SeoTags } from './SeoTags';
import { getSeoTagsConfig, getPageTitle } from '@/helpers/seo';

type SEOProps = {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  // https://developers.facebook.com/docs/sharing/best-practices#images
  imageWidth?: string;
  imageHeight?: string;
  schemaType?: string | string[];
  url?: string | null;
  robotsContent?: string;
  createdAt?: string;
  updatedAt?: string;
};

const SEO = ({
  title,
  description,
  image,
  imageWidth,
  imageHeight,
  schemaType,
  url,
  robotsContent = 'all',
  keywords,
  createdAt,
  updatedAt
}: SEOProps) => {
  const router = useRouter();
  const { license, hostname, canonicalUrl } = getUrlInfoFromRouter(router);

  const seoTagsConfig = getSeoTagsConfig({
    license,
    url: url ?? undefined,
    robotsContent,
    title,
    description,
    keywords,
    image,
    imageWidth,
    imageHeight,
    createdAt,
    updatedAt
  });

  return (
    <Head>
      {/* Title need to be declared here to get the right update (The render of title in SeoTags not works properly) */}
      <title key="pageTitle">
        {getPageTitle(seoTagsConfig?.title, seoTagsConfig?.['og:site_name'])}
      </title>

      <SeoTags canonicalUrl={canonicalUrl} config={seoTagsConfig} />

      {schemaType && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': schemaType,
              name: title,
              about: description,
              url: url ?? canonicalUrl
            })
          }}
        />
      )}
    </Head>
  );
};

SEO.getInitialProps = async () => {
  return {};
};

export default SEO;
