import { useState, ReactNode } from 'react';
import { ChevronButton } from '@/components/Button/ChevronButton';
import { ButtonProps, Grid, Box } from '@chakra-ui/react';

type ShowMoreProps = {
  children: ReactNode;
  show?: boolean;
  buttonProps: ButtonProps;
  buttonLabelShow?: string;
  buttonLabelHide?: string;
};
export function ShowMore({
  children,
  show = false,
  buttonLabelHide = 'hide',
  buttonLabelShow = 'show',
  buttonProps
}: ShowMoreProps) {
  const [visible, setVisible] = useState(show);

  return (
    <>
      <ChevronButton
        iconRight={visible ? 'top' : 'bottom'}
        onClick={() => {
          setVisible((b) => !b);
        }}
        {...buttonProps}
      >
        {visible ? buttonLabelHide : buttonLabelShow}
      </ChevronButton>

      <Grid
        gridTemplateRows={visible ? '1fr' : '0fr'}
        transition={'grid-template-rows .3s ease'}
      >
        <Box overflow="hidden">{children}</Box>
      </Grid>
    </>
  );
}
