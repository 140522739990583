import React, { useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { DataStore, TournamentType } from '@/components/GamesCatalog/types';
import { MenuPill } from '@/components/Menu';
import { TournamentCard, TournamentsGrid } from '@/components/Tournaments';
import OriaBackground from '@/components/Videos/OriaBackground';
import {
  getNoTournamentsLabel,
  getSortedAndGroupedTournaments,
  TournamentFilters
} from '@/helpers/tournaments';
import WithFeaturesDisable from '@/hoc/WithFeaturesDisable';
import { LicenceType } from '@/utils/multiDomains';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { toRem } from '@/helpers/toRem';
import { TournamentIcon } from '@/theme/Icons';
import SEO from '@/components/SEO';
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import { TournamentsContent } from '@/types/api/ge-strapi/tournament';
import { useAppSettings } from '@/context/AppSettings';
import { NavBar } from '@/components/Navbar';
import { PortalHeader } from '@/components/Portal';

const DynamicCategories = dynamic(
  () => import('@/components/GamesCatalog/Categories')
);

const DynamicCatalogBackground = dynamic(
  () => import('@/components/GoldenPalace/CatalogBackground')
);

export function TournamentsList({
  locale,
  license,
  tournaments,
  dataStore,
  strapiContent
}: {
  locale: string;
  license: LicenceType;
  tournaments: TournamentType[];
  dataStore: DataStore;
  strapiContent: TournamentsContent;
}) {
  const observeRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const config = useAppSettings();
  const [filter, setFilter] = useState(TournamentFilters.ALL);

  const { grouped, all } = useMemo(
    () => getSortedAndGroupedTournaments(tournaments),
    [tournaments]
  );

  const enableBackground = license !== 'F';

  const filteredTournaments =
    filter === TournamentFilters.ALL ? all : grouped[filter] || [];

  const filterMenuItems = [
    {
      value: TournamentFilters.ALL,
      label: t('tournament:all')
    },
    {
      value: TournamentFilters.UPCOMING,
      label: t('tournament:upcoming')
    },
    {
      value: TournamentFilters.ONGOING,
      label: t('tournament:ongoing')
    },
    {
      value: TournamentFilters.ENDED,
      label: t('tournament:ended')
    }
  ];

  return (
    <>
      <SEO
        title={strapiContent.metaTitle ?? t('tournament:tournaments')}
        description={strapiContent.metaDescription}
        keywords={strapiContent.metaKeywords}
      />

      <PortalHeader>
        <NavBar basic />
        <DynamicCategories
          index={Object.keys(dataStore?.categories || {}).length + 1}
          observeRef={observeRef}
          data={dataStore?.categories || {}}
          activeSlug="TOURNAMENTS"
        >
          {null}
        </DynamicCategories>
      </PortalHeader>

      {enableBackground &&
        WithFeaturesDisable(<OriaBackground />, 'OriaBackground')}
      {enableBackground &&
        WithFeaturesDisable(<DynamicCatalogBackground />, 'CatalogBackground')}

      <Container maxW="container.xl">
        {/* TITLE */}

        <Heading
          data-testid="tournaments-title"
          size="h4"
          fontSize={[toRem(18), toRem(24)]}
          lineHeight={[toRem(24), toRem(30)]}
          as="h1"
          mt={24}
          textTransform={'uppercase'}
          display={'flex'}
          gap={4}
          alignItems={'center'}
        >
          <TournamentIcon width={33} height={38} />
          <Box as="span">{t('tournament:tournaments')}</Box>
        </Heading>

        {/* FILTER MENU */}

        <MenuPill
          data-testid="tournaments-menu-pill"
          mt={8}
          items={filterMenuItems}
          current={filter}
          setCurrent={setFilter}
          justifyButtons={'start'}
        />

        {/* LIST */}

        <TournamentsGrid>
          {filteredTournaments.length === 0 && (
            <Text
              data-testid="tournaments-no-tournaments-label"
              fontSize={[toRem(18), toRem(24)]}
              lineHeight={[toRem(18), toRem(24)]}
              gridColumn={'1 / -1'}
              mt={16}
              align="center"
              dangerouslySetInnerHTML={{
                __html: t(getNoTournamentsLabel(filter))
              }}
            />
          )}
          {filteredTournaments?.map((tournament: TournamentType, index) => {
            return (
              <TournamentCard
                key={index}
                tournament={tournament}
                license={license}
              />
            );
          })}
        </TournamentsGrid>

        {/* CONTENT */}

        <Prose mt={16} data-testid={'tournaments-description-content'}>
          <Box
            className="seoContent"
            dangerouslySetInnerHTML={{
              __html: strapiContent.descriptionContent
            }}
          ></Box>
        </Prose>

        {/* TEXT */}
      </Container>
    </>
  );
}
