import {
  Text,
  TableContainer,
  Table,
  Th,
  Tr,
  Thead,
  Tbody,
  Heading,
  Box,
  Button,
  Center
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import {
  TournamentPersonalRanking,
  TournamentRankingLine,
  TournamentRankingType,
  TournamentStatusType
} from './index';
import { toRem } from '@/helpers/toRem';
import usePagination from '@/hooks/usePagination';
import { Pagination } from '../Pagination';
import { useAuth } from '@/context/Auth';
import { useMemo } from 'react';
import { humanDate } from '@/helpers/humanDate';
import { TournamentStatus } from '@/helpers/tournaments';

type RankingProps = {
  ranking: TournamentRankingType[];
  perPage: number;
  updatedAt?: string;
  winnerCount?: number;
  isRankingFinished?: boolean;
  hasLeaderboard?: boolean;
  tournamentStatus: TournamentStatusType;
};

const thStyles = {
  fontSize: [toRem(15), toRem(18)],
  lineHeight: [toRem(18), toRem(22)],
  color: 'figma.grey.60',
  padding: toRem(10),
  border: 0
};

export const TournamentRanking = ({
  perPage,
  ranking,
  updatedAt,
  winnerCount,
  isRankingFinished,
  tournamentStatus,
  hasLeaderboard = true
}: RankingProps) => {
  const { t, lang } = useTranslation();
  const { isLogged, userData } = useAuth();

  const userRankData = useMemo(() => {
    if (!isLogged) return null;
    return ranking.find((r) => r.userId === userData?.id);
  }, [isLogged, userData, ranking]);

  const userPage = userRankData
    ? Math.ceil(
        (ranking.findIndex((r) => r.userId === userRankData.userId) + 1) /
          perPage
      )
    : null;

  const {
    pageData,
    gotoPage,
    currentPage,
    totalPage,
    gotoFirstPage,
    gotoLastPage,
    gotoNextPage,
    gotoPreviousPage
  } = usePagination({
    perPage,
    initialData: ranking
  });

  const showPersonalRanking =
    userRankData && userPage && currentPage !== userPage;

  if (!hasLeaderboard) {
    return (
      <>
        <Heading
          as="h4"
          mt="16"
          fontSize={[toRem(18), toRem(24)]}
          lineHeight={[toRem(24), toRem(30)]}
          data-testid="tournament-ranking-without-leaderboard"
        >
          {t('tournament:ranking')}
        </Heading>
        <Text
          mt="10"
          fontSize={[toRem(15), toRem(18)]}
          lineHeight={[toRem(23), toRem(26)]}
        >
          {t('tournament:rankingInGame')}
        </Text>
      </>
    );
  }

  return (
    <>
      <Heading
        as="h4"
        mt="16"
        fontSize={[toRem(18), toRem(24)]}
        lineHeight={[toRem(24), toRem(30)]}
        data-testid="tournament-ranking-with-leaderboard"
      >
        {isRankingFinished
          ? t('tournament:finalRanking')
          : t('tournament:ranking')}
      </Heading>
      {updatedAt ? (
        <Text
          mt="10"
          fontSize={[toRem(15), toRem(18)]}
          lineHeight={[toRem(23), toRem(26)]}
        >
          <Box as="span" color="whiteAlpha.600">
            {t('tournament:lastUpdate')}:
          </Box>
          <Box as="span" ml="2" color="white">
            {humanDate(updatedAt, lang).split(', ').join(' - ')}
          </Box>
        </Text>
      ) : null}
      {!isRankingFinished && tournamentStatus === TournamentStatus.ENDED ? (
        <Text
          as="span"
          bg={'tournament.personalRanking.bg'}
          color={'tournament.personalRanking.color'}
          alignSelf={'start'}
          fontSize={toRem(12)}
          lineHeight={toRem(14)}
          px={1}
          py={1.5}
          mt={5}
          mb={-4}
          borderRadius={6}
        >
          {t('tournament:finalRankingComingSoon')}
        </Text>
      ) : null}

      <TableContainer mt="10" data-testid={'tournament-ranking-table'}>
        <Table>
          <Thead>
            <Tr
              borderTop="0"
              borderBottom="1px solid"
              borderBottomColor="whiteAlpha.100"
            >
              <Th {...thStyles} textTransform="none">
                {t('tournament:ranking')}
              </Th>
              <Th {...thStyles} textTransform="none">
                {t('tournament:player')}
              </Th>
              <Th {...thStyles} textTransform="none">
                {t('tournament:score')}
              </Th>
              <Th {...thStyles} textTransform="none">
                {t('tournament:gain')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {pageData.map((ranking, index) => (
              <TournamentRankingLine
                winnerCount={winnerCount}
                highlighted={ranking.userId === userRankData?.userId}
                isLast={index === pageData.length - 1}
                key={index}
                ranking={ranking}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {ranking.length === 0 ? (
        <Text textAlign={'center'} maxWidth={400} mx="auto" mt={8}>
          {t('tournament:emptyLeaderboard')}
        </Text>
      ) : null}

      <Pagination
        data-testid={'tournament-ranking-pagination'}
        onClickFirstPage={gotoFirstPage}
        onClickLastPage={gotoLastPage}
        onClickPreviousPage={gotoPreviousPage}
        onClickNextPage={gotoNextPage}
        currentPage={currentPage}
        totalPage={totalPage}
      />
      {showPersonalRanking ? (
        <>
          <TournamentPersonalRanking
            title={t('tournament:yourPosition')}
            ranking={userRankData}
          />

          <Button
            variant="outline"
            boxSizing="border-box"
            fontSize={toRem(15)}
            lineHeight={toRem(18)}
            px="4"
            py="1.5"
            fontWeight="500"
            borderRadius="full"
            border="1px solid"
            size="small"
            display="flex"
            alignItems={'center'}
            flexFlow={'row nowrap'}
            borderColor="buttonPrimary"
            alignSelf={'start'}
            mt="8"
            onClick={() => gotoPage(userPage)}
          >
            {t('tournament:seeRankingAroundMe')}
          </Button>
        </>
      ) : null}
    </>
  );
};
