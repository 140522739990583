import { Container, ContainerProps } from '@chakra-ui/react';

type TournamentSectionProps = ContainerProps & {
  name: string;
  isRow?: boolean;
  withBottomBorder?: boolean;
  withTopBorder?: boolean;
};

export const TournamentSection = ({
  name,
  isRow = false,
  children,
  withBottomBorder = false,
  withTopBorder = false,
  ...props
}: TournamentSectionProps) => {
  const withBorder = withBottomBorder || withTopBorder;

  const lineStyles = {
    bg: 'whiteAlpha.200',
    height: '1px',
    position: 'absolute',
    inset: 'auto 1rem'
  };

  return (
    <Container
      data-testid={`tournament-section-${name}`}
      as="section"
      display={'flex'}
      maxW="container.xl"
      width="100%"
      mx="auto"
      marginBottom={['2rem']}
      py={withBorder ? 10 : 0}
      paddingX={['1rem']}
      borderColor="whiteAlpha.200"
      position={'relative'}
      flexDirection={isRow ? 'row' : 'column'}
      flexWrap={isRow ? 'wrap' : 'nowrap'}
      alignItems={isRow ? 'center' : 'start'}
      {...props}
      _before={{
        ...lineStyles,
        content: withTopBorder ? '""' : 'none',
        top: 0
      }}
      _after={{
        ...lineStyles,
        content: withBottomBorder ? '""' : 'none',
        bottom: 0
      }}
    >
      {children}
    </Container>
  );
};
