import { Td, Tr, TableRowProps } from '@chakra-ui/react';
import { TournamentRankingMedalIcon } from './TournamentRankingMedalIcon';
import { TournamentRankingType } from './types';
import { CurrencyBase, humanCurrency } from '@/helpers/humanCurrency';

type TournamentRankingLineProps = TableRowProps & {
  ranking: TournamentRankingType;
  isLast?: boolean;
  highlighted?: boolean;
  winnerCount?: number;
};
export const TournamentRankingLine = ({
  ranking: { rank, username, points, prize },
  isLast,
  highlighted,
  winnerCount,
  ...props
}: TournamentRankingLineProps) => {
  const lineStyleHover = {
    _hover: {
      bg: highlighted
        ? 'tournament.personalRanking.bg'
        : 'tournament.rankingLine.bgHover'
    }
  };

  const lineStyle = !isLast
    ? {
        borderTop: 0,
        borderBottom: '1px solid',
        borderBottomColor: 'whiteAlpha.100'
      }
    : {
        borderBottom: 0,
        borderTop: 0
      };

  return (
    <Tr
      data-testid={'tournament-ranking-table-line'}
      {...props}
      {...lineStyle}
      {...lineStyleHover}
      bg={highlighted ? 'tournament.personalRanking.bg' : 'transparent'}
      color={highlighted ? 'tournament.personalRanking.color' : 'white'}
    >
      <Td {...lineStyle}>
        <TournamentRankingMedalIcon
          rank={rank}
          withIcon={rank <= (winnerCount || 3)}
        />
      </Td>
      <Td {...lineStyle}>{username}</Td>
      <Td {...lineStyle}>{points}</Td>
      <Td {...lineStyle}>
        {typeof prize === 'number'
          ? humanCurrency(prize, 'fr', 'EUR', CurrencyBase.EURO)
          : prize}
      </Td>
    </Tr>
  );
};
