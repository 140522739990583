import { Box, BoxProps, HStack, StackProps } from '@chakra-ui/react';
import React, {
  useEffect,
  useState,
  UIEvent,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { isAtEdge } from '@/helpers/scroll';

type PaginationLineProps<TItem, TProps> = BoxProps & {
  data: TItem[];
  renderItem: (
    props: PaginationLineRenderItemProps<TItem> & TProps
  ) => JSX.Element;
  renderItemProps: TProps;
  hasScroll?: boolean;
  hasPagination?: boolean;
  itemsPerPage?: number;
  stackProps?: StackProps;
  totalPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  itemGap?: number | string;
};

export function PaginationLine<TItem, TProps>({
  data,
  hasScroll = false,
  hasPagination = false,
  itemsPerPage = 5,
  stackProps,
  totalPage,
  currentPage,
  setCurrentPage,
  renderItem: RenderItem,
  renderItemProps,
  itemGap = 2,
  children,
  ...props
}: PaginationLineProps<TItem, TProps>) {
  const lineRef = useRef<HTMLDivElement>(null);

  const [currentPosition, setCurrentPosition] = useState(1);
  const [maxCurrentPosition, setMaxCurrentPosition] = useState(1);
  useEffect(() => {
    setCurrentPosition(currentPage);
    if (maxCurrentPosition < currentPage) {
      setMaxCurrentPosition(currentPage);
    }
  }, [currentPage, maxCurrentPosition]);

  ////////////////////////////////
  // items to render
  ////////////////////////////////

  // we use maxCurrentPosition (not currentPosition) in order to avoid an item alreay rendered to be unrendered
  const items = useMemo(() => {
    return data.slice(
      0,
      Math.min(
        maxCurrentPosition * itemsPerPage + (hasScroll ? 1 : 0),
        data.length
      )
    );
  }, [data, itemsPerPage, hasScroll, maxCurrentPosition]);

  // The count of items to render depend if having scroll or not
  // when hasScroll -> render 1 more item to have effectively a scroll
  const renderItemCount = hasScroll ? itemsPerPage + 1 : itemsPerPage;

  // update currentPosition and maxCurrentPosition when scrolling
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (
      isAtEdge(e.currentTarget, 10, 'horizontal') &&
      items.length !== data.length
    ) {
      if (currentPosition < data.length / itemsPerPage) {
        setCurrentPosition((current) => current + 1);
        setMaxCurrentPosition((max) => max + 1);
      }
    }
  };

  ////////////////////////////////
  // MANAGE RESET PAGINATION
  ////////////////////////////////

  const resetPagination = useCallback(() => {
    setCurrentPage(1);
    if (lineRef.current) {
      lineRef.current.scrollLeft = 0;
    }
  }, [lineRef, setCurrentPage]);

  // when itemsPerPage change -> reset
  useEffect(() => {
    resetPagination();
  }, [itemsPerPage, resetPagination]);

  // on window resize -> reset
  useEffect(() => {
    const onResize = (e: Event) => {
      resetPagination();
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [resetPagination]);

  const memoizedItems = useMemo(() => {
    return items?.map((item: TItem, index: number) => (
      <RenderItem
        item={item}
        key={index}
        px={typeof itemGap === 'number' ? itemGap / 2 : `calc(${itemGap} / 2)`}
        width={`calc((100% / ${itemsPerPage}) * (${itemsPerPage} / ${renderItemCount}))`}
        flexShrink="0"
        flexGrow="0"
        display="block"
        m="0!"
        {...renderItemProps}
      />
    ));
  }, [
    RenderItem,
    renderItemProps,
    items,
    renderItemCount,
    itemsPerPage,
    itemGap
  ]);

  return (
    <Box
      ref={lineRef}
      data-testid="pagination-line"
      overflow={hasScroll ? 'auto' : 'hidden'}
      onScroll={hasScroll ? handleScroll : undefined}
      {...props}
    >
      <HStack
        data-testid="pagination-line-items"
        alignItems={'start'}
        {...stackProps}
        w={`calc(100% * (${renderItemCount} / ${itemsPerPage}))`}
        transition={hasScroll ? 'none' : 'transform 0.3s ease'}
        transform={
          hasScroll
            ? 'translateX(0)'
            : `translateX(-${Math.min(
                100 * ((currentPage - 1) * (itemsPerPage / renderItemCount)),
                (100 / itemsPerPage) * (data.length - itemsPerPage)
              )}%)`
        }
      >
        {memoizedItems}
      </HStack>
    </Box>
  );
}

export type PaginationLineRenderItemProps<T> = {
  item: T;
  width: string;
  px: string | number;
  flexShrink: string;
  flexGrow: string;
  display: string;
  m: string;
};
