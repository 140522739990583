import { Grid, Heading, Image } from '@chakra-ui/react';
import { toRem } from '@/helpers/toRem';

export function TournamentHero({
  images,
  name,
  title
}: {
  images: { [key: string]: string };
  name: string;
  title?: string;
}) {
  return (
    <Grid bg="black" data-testid={'tournament-hero'}>
      {title ? (
        <Heading
          display={['none', 'none', 'block']}
          as="h1"
          fontSize={[toRem(36), toRem(64)]}
          lineHeight={[toRem(42), toRem(64)]}
          fontWeight="500"
          zIndex={1}
          gridColumn={'1 / 2'}
          gridRow={'1 / 2'}
          alignSelf={'center'}
          maxWidth={['none', 'none', 'calc(60% - 80px)', '48%', '33%']}
          ml={[0, 0, 8, 8, '15%']}
        >
          {title}
        </Heading>
      ) : null}
      <Image
        gridColumn={'1 / 2'}
        gridRow={'1 / 2'}
        srcSet={`${images.xs} 576w, ${images.md} 1440w, ${images.xl} 2880w`}
        src={images.xs}
        sizes={'(max-width: 576px) 576px, (max-width: 1440px) 1440px, 2880px'}
        maxHeight={'600px'}
        alt={name}
        width="100%"
        objectFit="cover"
      />
    </Grid>
  );
}
