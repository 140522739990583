import { HStack, StackProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { PaginationButton } from './PaginationButton';
import useTranslation from 'next-translate/useTranslation';

type PaginationProps = StackProps & {
  currentPage: number;
  totalPage: number;
  children?: ReactNode;
  onClickFirstPage?: () => void;
  onClickLastPage?: () => void;
  onClickNextPage?: () => void;
  onClickPreviousPage?: () => void;
  getUrl?: (currentPage: number) => string;
};

export const Pagination = ({
  onClickFirstPage,
  onClickPreviousPage,
  onClickNextPage,
  onClickLastPage,
  currentPage,
  totalPage,
  children,
  getUrl,

  ...props
}: PaginationProps) => {
  const showAllButtons = getUrl !== undefined;
  const { t } = useTranslation();
  const hasNextPage = currentPage < totalPage;
  const hasPreviousPage = currentPage > 1;
  return (
    <HStack spacing={4} mt="12" mx={'auto'} {...props}>
      {(onClickFirstPage || showAllButtons) && (
        <PaginationButton
          data-testid="pagination-btn-first-page"
          icon="FirstPageIcon"
          disabled={!hasPreviousPage}
          onClick={onClickFirstPage}
          href={getUrl?.(1)}
        />
      )}
      {(onClickPreviousPage || showAllButtons) && (
        <PaginationButton
          data-testid="pagination-btn-previous-page"
          icon="PreviousPageIcon"
          disabled={!hasPreviousPage}
          onClick={onClickPreviousPage}
          href={getUrl?.(hasPreviousPage ? currentPage - 1 : 1)}
        />
      )}
      {currentPage && totalPage && (
        <Text size="small" minWidth="100px" textAlign={'center'}>
          <span data-testid="pagination-current-page">{currentPage}</span>{' '}
          {t('common:of')}{' '}
          <span data-testid="pagination-total-page">{totalPage}</span>
        </Text>
      )}
      {children}
      {(onClickNextPage || showAllButtons) && (
        <PaginationButton
          data-testid="pagination-btn-next-page"
          icon="NextPageIcon"
          disabled={!hasNextPage}
          onClick={onClickNextPage}
          href={getUrl?.(hasNextPage ? currentPage + 1 : totalPage)}
        />
      )}
      {(onClickLastPage || showAllButtons) && (
        <PaginationButton
          data-testid="pagination-btn-last-page"
          icon="LastPageIcon"
          disabled={!hasNextPage}
          onClick={onClickLastPage}
          href={getUrl?.(totalPage)}
        />
      )}
    </HStack>
  );
};
