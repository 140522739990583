import { ReactNode } from 'react';
import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { ChevronLeft, ChevronTop } from '@/theme/Icons';
import { toRem } from '@/helpers/toRem';

type ChevronPosition = 'left' | 'right';
type ChevronIconType = 'left' | 'right' | 'top' | 'bottom';

interface ChevronButtonProps extends ButtonProps {
  iconLeft?: ChevronIconType;
  iconRight?: ChevronIconType;
}

function getIcon(
  iconType: ChevronIconType,
  position: ChevronPosition
): ReactNode {
  const commonStyles = {
    width: ['1.25rem'],
    height: ['1.25rem']
  };

  const spacings = {
    marginLeft: `${position === 'right' ? 1.5 : 0}`,
    marginRight: `${position === 'right' ? 0 : 1.5}`
  };

  switch (iconType) {
    default:
      null;
    case 'left':
      return <ChevronLeft {...commonStyles} {...spacings} />;
      break;
    case 'top':
      return <ChevronTop {...commonStyles} {...spacings} />;
      break;
    case 'right':
      return (
        <ChevronLeft
          transform={'rotate: 180deg'}
          {...commonStyles}
          {...spacings}
        />
      );
      break;
    case 'bottom':
      return (
        <ChevronTop
          transform={'rotate(180deg)'}
          {...commonStyles}
          {...spacings}
        />
      );
      break;
  }
}

export const ChevronButton = forwardRef<ChevronButtonProps, 'button'>(
  ({ iconLeft, iconRight, children, as: asTag = 'button', ...props }, ref) => {
    return (
      <Button
        variant="outline"
        boxSizing="border-box"
        fontSize={toRem(15)}
        lineHeight={toRem(18)}
        px=" 4"
        py=" 1.5"
        fontWeight="500"
        borderRadius="full"
        border="1px solid"
        size="small"
        display="flex"
        alignItems={'center'}
        flexFlow={'row nowrap'}
        ref={ref}
        as={asTag}
        {...props}
      >
        {iconLeft && getIcon(iconLeft, 'left')}
        {children}
        {iconRight && getIcon(iconRight, 'right')}
      </Button>
    );
  }
);
