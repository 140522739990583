export function isAtEdge(
  element: HTMLElement,
  offset = 0,
  type: 'horizontal' | 'vertical' = 'horizontal'
) {
  offset = Math.max(offset, 1);
  switch (type) {
    case 'horizontal':
      const { scrollLeft, scrollWidth, clientWidth } = element;
      const maxScrollLeft = scrollWidth - clientWidth;
      return scrollLeft >= maxScrollLeft - offset;
    case 'vertical':
      const { scrollTop, scrollHeight, clientHeight } = element;
      const maxScrollTop = scrollHeight - clientHeight;
      return scrollTop >= maxScrollTop - offset;
  }
}
