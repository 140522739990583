import {
  Button as ChakraButton,
  ButtonProps,
  HStack,
  StackProps,
  Text
} from '@chakra-ui/react';
import { toRem } from '@/helpers/toRem';
import Link from 'next/link';
import Slider from '../Slider/Slider';
import { FC } from 'react';

const Button = ChakraButton as FC<ButtonProps & { href?: string }>;

export type MenuPillProps<T> = StackProps & {
  current: T;
  setCurrent?: (newCurrent: any) => void;
  items: MenuPillOption[];
  justifyButtons?: 'center' | 'start' | 'end';
};

export type MenuPillOption = {
  value: string;
  label: string;
  link?: string;
};

export function MenuPill<T>({
  current,
  setCurrent,
  items,
  justifyButtons,
  ...props
}: MenuPillProps<T>) {
  return (
    <HStack as="nav" {...props}>
      <Slider root="div" justifyButtons={justifyButtons}>
        {items?.map(({ value, label, link }) => {
          const button = (
            <Button
              as={link ? 'a' : 'button'}
              data-testid={'menu-pill-button'}
              isActive={current === value}
              size="md"
              variant={'outlined'}
              fontSize={toRem(15)}
              lineHeight={toRem(18)}
              fontWeight="500"
              minWidth={'none'}
              borderRadius="full"
              border="1px solid"
              borderColor="whiteAlpha.400"
              _active={{
                bg: 'whiteAlpha.300'
              }}
              onClick={() => setCurrent?.(value)}
            >
              <Text as={'span'} minWidth={'3.75rem'} textAlign={'center'}>
                {label}
              </Text>
            </Button>
          );
          return link ? (
            <div key={value}>
              <Link href={link ?? ''} passHref>
                {button}
              </Link>
            </div>
          ) : (
            <div key={value}>{button}</div>
          );
        })}
      </Slider>
    </HStack>
  );
}
