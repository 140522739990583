import { ReactNode } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import {
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PreviousPageIcon
} from '@/theme/Icons';
import Link from 'next/link';

export type PaginationButtonProps = ButtonProps & {
  children?: ReactNode;
  icon: string;
  disabled?: boolean;
  href?: string;
};

const getIcon = (icon: string) => {
  switch (icon) {
    case 'FirstPageIcon':
      return <FirstPageIcon />;
    case 'PreviousPageIcon':
      return <PreviousPageIcon />;
    case 'NextPageIcon':
      return <NextPageIcon />;
    case 'LastPageIcon':
      return <LastPageIcon />;
  }
};

export const PaginationButton = ({
  children,
  icon,
  href,
  ...props
}: PaginationButtonProps) => {
  const button = (
    <Button
      as={href ? 'a' : 'button'}
      isDisabled={props.disabled}
      variant={'outline'}
      px="4"
      py="4"
      borderRadius="ten"
      color="white"
      padding=" 2.5"
      border="1px solid"
      borderColor="white"
      _hover={{
        bg: 'whiteAlpha.200'
      }}
      _disabled={{
        color: 'whiteAlpha.400!',
        borderColor: 'whiteAlpha.200!',
        bg: 'transparent!',
        cursor: 'default',
        _hover: {
          bg: 'transparent!'
        }
      }}
      {...props}
    >
      {getIcon(icon)}
      {children}
    </Button>
  );
  return href ? (
    <Link href={href ?? ''} passHref>
      {button}
    </Link>
  ) : (
    button
  );
};
