import { useCallback, useEffect, useMemo, useState } from 'react';

export default function usePagination<T>({
  initialData = [],
  perPage,
  needFullPage = false,
  initialCurrentPage = 1,
  initialTotalPage,
  useAsNavigation
}: {
  initialData?: T[];
  perPage: number;
  needFullPage?: boolean;
  initialCurrentPage?: number;
  initialTotalPage?: number;
  useAsNavigation?: boolean;
}) {
  const totalPage = useMemo(
    () =>
      initialTotalPage
        ? initialTotalPage
        : Math.ceil(initialData.length / perPage),
    [initialTotalPage, initialData.length, perPage]
  );

  const [currentPage, setCurrentPage] = useState<number>(initialCurrentPage);

  const getPageIndexes = useCallback(() => {
    const start = (currentPage - 1) * perPage;
    const end = currentPage * perPage;

    if (needFullPage && end >= initialData.length) {
      return [initialData.length - perPage, initialData.length];
    }

    return [start, end];
  }, [currentPage, perPage, needFullPage, initialData.length]);

  const [data, setData] = useState<T[]>(initialData);
  const [pageData, setPageData] = useState<T[]>(
    data.slice(...getPageIndexes())
  );

  const ensureNextPage = useCallback(
    (p: number) => {
      if (p > totalPage) return totalPage;
      return p;
    },
    [totalPage]
  );

  const ensurePreviousPage = useCallback((p: number) => {
    if (p < 1) return 1;
    return p;
  }, []);

  const gotoFirstPage = () => {
    setCurrentPage(1);
  };

  const gotoLastPage = () => {
    setCurrentPage(totalPage);
  };

  const gotoPage = (p: number) => {
    p = ensureNextPage(p);
    p = ensurePreviousPage(p);
    setCurrentPage(p);
  };

  const gotoNextPage = useCallback(() => {
    setCurrentPage(ensureNextPage(currentPage + 1));
  }, [currentPage, ensureNextPage]);

  const gotoPreviousPage = useCallback(() => {
    setCurrentPage(ensurePreviousPage(currentPage - 1));
  }, [currentPage, ensurePreviousPage]);

  useEffect(() => {
    setPageData(data.slice(...getPageIndexes()));
  }, [data, getPageIndexes]);

  const hasNextPage = currentPage < totalPage;
  const hasPreviousPage = currentPage > 1;

  useEffect(() => {
    if (useAsNavigation && initialCurrentPage !== currentPage) {
      setCurrentPage(initialCurrentPage);
    }
  }, [useAsNavigation, currentPage, initialCurrentPage]);

  return {
    currentPage,
    setCurrentPage,
    totalPage,
    pageData,
    gotoPage,
    gotoFirstPage,
    gotoLastPage,
    gotoNextPage,
    gotoPreviousPage,
    hasNextPage,
    hasPreviousPage,
    getPageIndexes
  };
}
