import { GiftIcon } from '@/theme/Icons';
import { Circle, BoxProps, HStack } from '@chakra-ui/react';

type TournamentRankingMedalIconProps = BoxProps & {
  rank: number;
  withIcon?: boolean;
};
export const TournamentRankingMedalIcon = ({
  rank,
  withIcon,
  ...props
}: TournamentRankingMedalIconProps) => {
  let bgColor = 'transparent';
  if (withIcon) {
    bgColor = 'tournament.medal.first';
  }

  return (
    <HStack spacing={4}>
      <Circle
        as="span"
        display={'inline-flex'}
        w={7}
        h={7}
        {...props}
        bg={bgColor}
      >
        {rank}
      </Circle>
      {withIcon && <GiftIcon />}
    </HStack>
  );
};
