import { useEffect } from 'react';

const useVisibilityChange = (
  visibleCallback: Function,
  hideCallback?: Function
) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      document?.addEventListener('visibilitychange', () => {
        if (document?.visibilityState === 'visible') {
          visibleCallback?.();
        } else {
          hideCallback?.();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useVisibilityChange;
